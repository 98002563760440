import React from 'react';
import { Facebook, Twitter } from 'lucide-react';

const TestimonialCard = ({ name, description, quote }) => (
  <div className="flex flex-col p-8 bg-white rounded-lg">
    <p className="text-gray-800 mb-8 text-lg leading-relaxed">
      {quote}
    </p>
    <div className="mt-auto">
      <div className="flex items-center gap-4">
        {/* <div className="w-12 h-12 bg-blue-100 rounded-full"></div> */}
        <div className="flex-1">
          <h3 className="font-semibold text-gray-900">{name}</h3>
          <p className="text-sm text-gray-600">{description}</p>
        </div>
        {/* <div className="flex gap-3">
          <a href="#" className="text-gray-600 hover:text-gray-900">
            <Facebook className="w-5 h-5" />
          </a>
          <a href="#" className="text-gray-600 hover:text-gray-900">
            <Twitter className="w-5 h-5" />
          </a>
        </div> */}
      </div>
    </div>
  </div>
);

const TestimonialsSection = () => {
  const testimonials = [
    {
      name: "Surendra",
      description: "Corporate Event planner",
      quote: "Yesterday I photographed a major product launch event in the morning. Thanks to Fotos' direct camera transfer and AI editing, I delivered a complete gallery of professional shots before the after-party even started. My client immediately used them for social media announcements, while their competitors were still waiting for their photographers to get home and start culling. In this industry, being first matters - Fotos has made same-day delivery my new standard."
    },
    {
      name: "Ramana Veletti",
      description: "Wedding Photographer",
      quote: "I used to dread when couples asked for 'sneak peeks' for social media. Now with Fotos, I transfer photos right from my camera during reception breaks, and the AI handles editing in my style. My couples wake up the morning after their wedding to a curated gallery of 50+ perfect shots, while I'm already focused on my next event. Going from 4-week delivery to same-day previews has transformed my business - my referral rate has doubled."
    },
    // {
    //   name: "Name Surname",
    //   description: "Description",
    //   quote: "What excites me about Fotos is that, it makes me use it in a simple way for the general workflow and is even simple to use."
    // }
  ];

  return (
    <div className="w-full bg-gray-50 py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">
          Users say that..!!
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              name={testimonial.name}
              description={testimonial.description}
              quote={testimonial.quote}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;