import React from "react";
import BG from "../assets/bg-sprinkle.png";
import FloatingImage from './floating-image'
import Event1 from '../assets/Event1.png'
import Event2 from '../assets/Event2.png'
import Event3 from '../assets/Event3.png'
import Event4 from '../assets/Event4.png'
import Event5 from '../assets/Event5.png'
import Event6 from '../assets/Event6.png'
import Event7 from '../assets/Event7.png'

const Hero = ({ onExploreClick }) => {

  return (
    <div className="relative bg-black w-screen h-screen overflow-hidden">
      <div className="absolute inset-0 bg-cover" style={{ backgroundImage: `url(${BG})` }}></div>
      <div className="absolute w-full h-full top-0 left-0 inset-0 bg-black bg-opacity-30">
        {/* floating images around the content in center */}
        <FloatingImage
        src={Event1}
        alt="Conference event"
        className="absolute top-[10%] left-[40%] w-[30%] h-[10%] md:w-[18%]  md:h-[18%] "
      />
      
      <FloatingImage
        src={Event2}
        alt="Conference workshop"
        className="absolute top-[10%] left-[70%] md:top-[2%] md:left-[68%] w-[30%] h-[10%] md:w-[18%]  md:h-[18%] "
      />
      
      <FloatingImage
        src={Event3}
        alt="Conference audience"
        className="absolute top-[50%] left-[60%] md:top-[12%] md:left-[80%] w-[30%] h-[10%] md:w-[18%]  md:h-[18%] "
      />

      {/* Bottom row images */}
      <FloatingImage
        src={Event4}
        alt="Team meeting"
        className="absolute top-[55%] left-[35%] md:top-[15%] md:left-[55%] w-[30%] h-[10%] md:w-[18%]  md:h-[18%] "
      />
      
      <FloatingImage
        src={Event5}
        alt="Conference hall"
        className="absolute top-[25%] left-[15%] md:top-[7%] md:left-[22%] w-[30%] h-[10%] md:w-[18%] md:h-[18%]"
      />
      
      <FloatingImage
        src={Event6}
        alt="Audience view"
        className="absolute bottom-[0%] left-[4%] md:bottom-[30%] md:left-[4%] w-[30%] md:w-[12%] h-[11%] md:h-[11%] "
      />
      
      <FloatingImage
        src={Event7}
        alt="Audience view"
        className="absolute bottom-[35%] md:bottom-[80%] left-[4%] md:left-[4%] w-[35%] md:w-[15%] h-[10%] md:h-[18%]"
      />
      </div>
      <div className="relative z-10 text-white flex flex-col justify-center items-center h-full">
        <div className="relative z-10 text-center">
        <h1 className="w-full font-bold text-white text-opacity-75 text-2xl md:text-4xl lg:text-5xl">
          Send Event photos in{' '}
          <span className="relative inline-block mt-2 md:m-0">
            <span>1 <span className="relative">
              <span className="absolute left-0 top-1/2 w-full border-t-2 border-white"></span>
              Week
            </span></span>
            <span className={`absolute left-0 top-1 md:top-0 lg:top-0 w-full font-bold text-white text-3xl md:text-5xl lg:text-6xl`} style={{ transform: 'rotate(-5deg) translateY(-100%)', fontFamily: 'Caveat, cursive' }}>
              Minute
            </span>
          </span>
        </h1>
        
        <button
          className="mt-8 rounded rounded-10 bg-gradient-to-r from-white to-[#999999] px-8 py-2 text-lg font-semibold text-black transition-all hover:bg-white"
          onClick={onExploreClick}
        >
          Explore How ?
        </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
