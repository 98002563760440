import React, { useState } from 'react';
import { ChevronRight } from 'lucide-react';

const FAQItem = ({ question, isOpen, onClick }) => (
  <div className="border-b last:border-b-0">
    <button
      className="flex justify-between items-center w-full py-4 px-6 text-left hover:bg-gray-50 transition-colors"
      onClick={onClick}
    >
      <span className="font-medium">{question}</span>
      <ChevronRight 
        className={`h-5 w-5 transition-transform duration-200 ${isOpen ? 'rotate-90' : ''}`}
      />
    </button>
  </div>
);

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "How do I get images from my DSLR to Fotos?",
      answer: "You can easily transfer images from your DSLR to Fotos through our desktop application or using our Lightroom plugin. Simply connect your camera, select the images, and upload them directly to your Fotos account."
    },
    {
      question: "Is Fotos compatible with my camera?",
      answer: "Fotos is compatible with all major camera brands including Canon, Nikon, Sony, and others. We support various image formats including RAW files from most modern DSLR and mirrorless cameras."
    },
    {
      question: "How much time can Fotos save me?",
      answer: "Photographers typically save 3-4 hours per event using Fotos. Our AI-powered culling and quick sharing features significantly reduce post-processing and delivery time."
    },
    {
      question: "Does Fotos store my images securely?",
      answer: "Yes, all images on Fotos are stored securely using enterprise-grade encryption. We use AWS servers with multiple redundancies to ensure your photos are safe and always accessible."
    },
    {
      question: "What makes Fotos different from other photography tools?",
      answer: "Fotos stands out with its AI-powered features, instant sharing capabilities, and photographer-focused workflow. Our platform combines gallery management, client interaction, and photo delivery in one seamless solution."
    }
  ];

  return (
    <div className="w-full max-w-3xl mx-auto px-4 py-24">
      <h2 className="text-3xl font-bold mb-4">People also asked</h2>
      
      <p className="text-gray-600 mb-8">
        If you don't see your question addressed or need further clarification, please 
        don't hesitate to reach out to us. We're always happy to provide additional 
        information and address any concerns you may have.
      </p>

      <div className="border rounded-lg divide-y">
        {faqs.map((faq, index) => (
          <div key={index}>
            <FAQItem
              question={faq.question}
              isOpen={openIndex === index}
              onClick={() => setOpenIndex(openIndex === index ? null : index)}
            />
            {openIndex === index && (
              <div className="px-6 py-4 text-gray-600 bg-gray-50">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;