import React, { useEffect, useState } from "react";
import './layout.css';
import Hero from "./components/Hero";
import Demo from "./components/Demo";
import ComparisonTable from "./components/comparisonTable";
import TestimonialSection from "./components/testimonials";
import FAQSection from "./components/FAQSection";
import Footer from "./components/footer";
import Navbar from "./components/Navbar";

const Layout = () => {
  const [animateNextComponent, setAnimateNextComponent] = useState(false);
  const [showNextComponent, setShowNextComponent] = useState(false);

  const handleExploreClick = () => {
    setShowNextComponent(true);
  };

  useEffect(() => {
    if (showNextComponent) {
      setTimeout(() => {
        setAnimateNextComponent(true);
      }, 100);
    }
  }, [showNextComponent]);

  return (
    <div className="layout">
      {animateNextComponent && (
        <div className="transition-opacity duration-500 ease-in-out">
          <Navbar />
        </div>
      )}
      <main>
        <Hero onExploreClick={handleExploreClick} />
        {showNextComponent && (
          <div
            className={`absolute inset-0 overflow-hidden bg-white z-20 transition-all duration-1000 ease-in-out ${
              animateNextComponent 
                ? "translate-y-0 opacity-100" 
                : "translate-y-full opacity-0"
            }`}
          >
            <div className="space-y-8 overflow-hidden animate-fadeIn hide-scrollbar"
              style={{
                scrollSnapType: 'y mandatory',
                height: '100vh',
                overflowY: 'scroll',
                scrollBehavior: 'smooth'
              }}>
              <div style={{ scrollSnapAlign: 'start', height: '100vh' }}><Demo /></div>
              <div style={{ scrollSnapAlign: 'start', height: '120vh md:100vh' }}><ComparisonTable /></div>
              <div style={{ scrollSnapAlign: 'start', height: '80vh sm:100vh' }}><TestimonialSection /></div>
              <div style={{ scrollSnapAlign: 'start', height: '80vh sm:100vh' }}><FAQSection /></div>
              <div style={{ scrollSnapAlign: 'start' }}><Footer /></div>
            </div>
          
          </div>
        )}
      </main>
    </div>
  );
};

export default Layout;
