import React from 'react';
import { Upload, Wand2, Palette, Image } from 'lucide-react';
import Logo from '../assets/monotype-white.svg';

const Footer = () => {
  return (
    <div className="bg-black text-white py-8">
      {/* CTA Section */}
      <div className="max-w-4xl mx-auto text-center px-4 mb-12">
        <p className="text-gray-400 mb-4">Still not sure ?</p>
        <h2 className="text-4xl md:text-5xl font-bold mb-4">
          Get 1<sup>st</sup> Event FREE and<br />
          Find if it's a fit.!
        </h2>
        <button className="bg-white text-black px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors mb-2">
          Sign Up
        </button>
        <p className="text-sm text-gray-400">*no credit card required</p>
      </div>

      {/* Logo and Features Section */}
      <div className="w-screen mx-auto px-4 gap-2 flex flex-col items-start">
        <img 
          src={Logo}
          alt="Fotos Logo" 
          className="h-52 object-contain"
        />
        
        <p className="text-lg text-gray-300 max-w-2xl">
          Most loved by professional event planners and
          <br/>
          photographers worldwide.
        </p>

        {/* Feature Pills */}
        <div className="flex flex-wrap gap-4 mb-12">
          <div className="flex items-center text-sm gap-2 px-4 py-2 border border-white rounded-md">
            <Upload size={20} />
            <span>MAGIC UPLOAD</span>
          </div>
          <div className="flex items-center text-sm gap-2 px-4 py-2 border border-white rounded-md">
            <Wand2 size={20} />
            <span>AI EDITING</span>
          </div>
          <div className="flex items-center text-sm gap-2 px-4 py-2 border border-white rounded-md">
            <Palette size={20} />
            <span>AUTO BRANDING</span>
          </div>
          <div className="flex items-center text-sm gap-2 px-4 py-2 border border-white rounded-md">
            <Image size={20} />
            <span>SELFIE GALLERIES</span>
          </div>
        </div>

        {/* Copyright */}
        <div className="text-gray-500 text-sm">
          © 2024 by Fotos ( Princo solutions Pvt Ltd )
        </div>
      </div>
    </div>
  );
};

export default Footer;