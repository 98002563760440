import React from 'react';
import { CheckSquare } from 'lucide-react';

const ComparisonTable = () => {
  const features = [
    { name: 'Custom Galleries', platforms: ['Pix time', 'Piceset', 'Promagic', 'Fotos'] },
    { name: 'Networking', platforms: ['Promagic', 'Fotos'] },
    { name: 'Branding', platforms: ['Pix time', 'Piceset', 'Promagic', 'Fotos'] },
    { name: 'QR based link sharing', platforms: ['Pix time', 'Piceset', 'Promagic', 'Fotos'] },
    { name: 'AI retouch', platforms: ['Fotos'] },
    { name: 'AI Culling', platforms: ['Fotos'] },
    { name: 'Upload with Camera', platforms: ['Fotos'] },
    { name: 'Fotos Card', platforms: ['Fotos'] },
    { name: 'Analytics - Engagement, Storage', platforms: ['Promagic', 'Fotos'] },
    { name: 'Lightroom Plugin', platforms: ['Pix time', 'Fotos'] },
  ];

  const platforms = ['Dryve', 'Pix time', 'Piceset', 'Promagic', 'Fotos'];

  return (
    <div className="w-screen h-full py-24 md:h-screen flex flex-col items-center justify-center px-1 md:px-10">
      <div className="rounded-lg border w-full overflow-x-auto">
        <table className="w-full divide-y divide-gray-200 overflow-hidden">
          {/* Table Header */}
          <thead className="bg-gray-50">
            <tr>
              <th className="w-2/7 p-1 md:px-2 md:py-2 text-left text-xs md:text-sm font-semibold text-gray-900">
                Aspect
              </th>
              {platforms.map((platform) => (
                <th
                  key={platform}
                  className="w-1/6 p-1 text-left text-xs md:text-sm font-semibold text-gray-900"
                >
                  {platform}
                </th>
              ))}
            </tr>
          </thead>

          {/* Table Body */}
          <tbody className="divide-y divide-gray-200 bg-white">
            {features.map((feature) => (
              <tr key={feature.name} className="hover:bg-gray-50">
                <td className="px-2 py-4 text-xs md:text-sm text-gray-900">
                  {feature.name}
                </td>
                {platforms.map((platform) => (
                  <td key={`${feature.name}-${platform}`} className="px-2 py-4">
                    {feature.platforms.includes(platform) ? (
                      <CheckSquare size={20} color='green' />
                    ) : null}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4 text-3xl font-bold text-gray-800">
        It’s not only about features, but also the experience..!!
      </div>
    </div>
  );
};

export default ComparisonTable;