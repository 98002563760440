import React, { useState } from 'react';
import Logo from '../assets/monotype-black.svg';
import Logo1 from '../assets/isotype-black.svg';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-white shadow-0">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-row justify-between items-center h-16 md:h-20">
          {/* Logo */}
          <div className="flex flex-row items-center">
            <img 
              src={Logo}
              alt="Fotos Logo" 
              className="h-12 md:h-16"
            />
            {/* <img 
              src={Logo1}
              alt="Fotos Logo" 
              className="h-16"
            /> */}
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isMenuOpen ? (
                  <path d="M6 18L18 6M6 6l12 12" />
                ) : (
                  <path d="M4 6h16M4 12h16M4 18h16" />
                )}
              </svg>
            </button>
          </div>

          {/* Desktop menu */}
          <div className='hidden md:flex flex-row'>
            <button
              onClick={() => {
                const demoSection = document.getElementById("demo");
                demoSection.scrollIntoView({ behavior: "smooth" });
              }}
              className="mx-1 px-4 py-2 bg-black text-white rounded-lg font-medium hover:bg-gray-800 transition-colors"
            >
              See Demo
            </button>
            <button
              onClick={() => {
                window.location.href = "https://event.getfotos.live";
              }}
              className="mx-1 px-4 py-2 bg-white border border-black text-black rounded-lg font-medium transition-colors"
            >
              Get Started
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="md:hidden pb-4">
            <button
              onClick={() => {
                const demoSection = document.getElementById("demo");
                demoSection.scrollIntoView({ behavior: "smooth" });
                setIsMenuOpen(false);
              }}
              className="block w-full px-4 py-2 mb-2 bg-black text-white rounded-lg font-medium hover:bg-gray-800 transition-colors"
            >
              See Demo
            </button>
            <button
              onClick={() => {
                window.location.href = "https://event.getfotos.live";
              }}
              className="block w-full px-4 py-2 bg-white border border-black text-black rounded-lg font-medium transition-colors"
            >
              Get Started
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;