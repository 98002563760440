import React from "react";

const Demo = () => {
  return (
    <div id="demo" className="w-screen h-screen bg-white flex flex-col items-center justify-center px-8 md:px-20 pt-28">
      <div className="text-3xl font-bold text-gray-800 mb-4">
        Explore Fotos under 15 Clicks
      </div>
      <iframe 
        title="Fotos Demo" 
        src="https://capture.navattic.com/cm3wpq3kq000003l421w44wvu" 
        className="w-full h-full rounded-[40px] shadow-[0_0_20px_0px_rgba(0,0,0,0.54)] overflow-hidden" 
        allow="fullscreen" 
        data-navattic-demo-id="cm3wpq3kq000003l421w44wvu">
      </iframe>
    </div>
  );
};

export default Demo;
