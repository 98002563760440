import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

function FloatingImage({ src, alt, width, height, className = '' }) {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const interval = setInterval(() => {
            setPosition({
                x: Math.random() * 20 - 10,
                y: Math.random() * 20 - 10,
            });
        }, 200);

        return () => clearInterval(interval);
    }, []);

    return (
        <motion.div
            className={`relative overflow-hidden rounded-2xl ${className}`}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{
                opacity: 1,
                scale: 1,
                x: position.x,
                y: position.y,
            }}
            transition={{
                duration: 2,
                ease: 'easeInOut',
            }}
            style={{
                width,
                height,
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            }}
            whileHover={{ scale: 1.05, zIndex: 10 }}
            drag
            dragConstraints={{
                top: -50,
                left: -50,
                right: 50,
                bottom: 50,
            }}
        >
            <img
                src={src}
                alt={alt}
                width={width}
                height={height}
                className="h-full w-full object-cover"
            />
        </motion.div>
    );
}

export default FloatingImage;