import React from "react";

function Upgrade() {
  const plans = [
    { id: 1, name: "Pro", storage: "1 TB", price: "$9.99/month" },
    { id: 2, name: "Business", storage: "5 TB", price: "$24.99/month" },
    { id: 3, name: "Enterprise", storage: "Unlimited", price: "Contact Us" },
  ];

  return (
    <div className="container mx-auto mt-10 p-4">
      <h2 className="text-3xl font-bold mb-6 text-purple-600">
        Upgrade Your Plan
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {plans.map((plan) => (
          <div key={plan.id} className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
            <p className="text-gray-600 mb-2">{plan.storage} Storage</p>
            <p className="text-purple-500 font-semibold mb-4">{plan.price}</p>
            <button className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full">
              Select
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Upgrade;
