import logo from './logo.svg';
import './App.css';
import LandingPage from "./landingPage";

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./components/Home";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Events from "./components/Events";
import EventDetails from "./components/EventDetails";
import Cloud from "./components/Cloud";
import Upgrade from "./components/Upgrade";
import Layout from "./Layout";

function App() {
  // return <LandingPage />;
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* <Route exact path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/:id" element={<EventDetails />} />
          <Route path="/cloud" element={<Cloud />} />
          <Route path="/upgrade" element={<Upgrade />} /> */}
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
